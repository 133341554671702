import SectionBuilder from '../templates/SectionBuilder';
import { returnStaticProps } from '../lib/_helpers';
import { returnTemplate } from '../lib/_client';
import { PAGE_WP_TYPE } from '../contentTypes/page';

export default function Template(props) {
  return returnTemplate(props, SectionBuilder);
}

export async function getStaticProps(props) {
  props.params = { slug: 'home' };

  return await returnStaticProps({ ...props, type: PAGE_WP_TYPE });
}
