import dynamic from 'next/dynamic';
import Layout from '../templates/layout';
import BlogLayout from '../templates/BlogLayout';
import Authenticator from '../templates/partials/Authenticator';

const SavaLayout = dynamic(import(':templates/SavaLayout'));
const SavaSectionBuilder = dynamic(import(':templates/SavaSectionBuilder'));

export const returnTemplate = (props, Component) => {
  const { construct, globals, mode, requiresAuth, previewData } = props;

  if (construct.useNewBlocks) {
    return (
      <SavaLayout globals={globals} previewData={previewData} mode={mode}>
        <SavaSectionBuilder blocks={construct.blocks} />
      </SavaLayout>
    );
  }

  if (requiresAuth) {
    return (
      <Authenticator
        action={'Redirecting'}
        endpoint={'www-vercel-authenticate'}
      />
    );
  }

  return (
    <Layout
      {...globals}
      alternativePages={construct?.alternativePages}
      mode={mode}
      meta={construct?.meta}
      previewData={previewData}
    >
      <Component
        {...construct}
        // we prefer a `pageLocale` if available - this is to support the "requested locale not available" experience.
        locale={mode?.pageLocale || mode?.locale}
        pageLocaleDefaulted={mode?.pageLocaleDefaulted}
      />
    </Layout>
  );
};

export const returnBlogTemplate = (props, Component) => {
  const { construct, globals, mode, requiresAuth, previewData } = props;
  return requiresAuth ? (
    <Authenticator
      action={'Redirecting'}
      endpoint={'www-vercel-authenticate'}
    />
  ) : (
    <BlogLayout
      {...globals}
      alternativePages={construct?.alternativePages}
      mode={mode}
      meta={construct?.meta}
      previewData={previewData}
    >
      <Component
        {...construct}
        // we prefer a `pageLocale` if available - this is to support the "requested locale not available" experience.
        // this isnt used on blog but we're to scared to remove it...
        locale={mode?.pageLocale || mode?.locale}
        pageLocaleDefaulted={mode?.pageLocaleDefaulted}
        globals={globals}
        mode={mode}
      />
    </BlogLayout>
  );
};
